import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Video from "../components/Video";
import ImgBIO30037_W800 from "../images/ImgBIO30037_W800";
import ImgBIO31090_W800 from "../images/ImgBIO31090_W800";
import ImgBIO31124_W800 from "../images/ImgBIO31124_W800";
import ImgBIO31125_W800 from "../images/ImgBIO31125_W800";

function BM3Physican() {
  return (
    <Layout>
      <SEO
        title="BIOMONITOR III for Physicians"
        keywords={[`biomonitor`, `cardiac diagnostics`, `icm`]}
      />
      <div
        className="bg-white py-12 bg-cover bg-no-repeat bg-center mx-auto"
        style={{
          backgroundImage: `url(https://res.cloudinary.com/binc/image/upload/v1535390263/product/1440x325_biomonitor_2.jpg)`
        }}
      >
        <div className="container mx-auto p-4 flex flex-col justify-center banner-height">
          <div className="text-3xl font-bold mb-2 uppercase">
            BIOMONITOR III Injectable Cardiac Monitor
          </div>
          <div className="heading">Cardiac Monitoring. Made Clearer.</div>
          <div>
            BIOMONITOR III is an injectable cardiac monitoring device designed
            to help physicians document evidence of a patient’s suspected
            cardiac arrhythmia or unexplained syncope.
          </div>
        </div>
      </div>
      <div className="bg-bioGrayBlue py-12">
        <div className="container mx-auto p-4 text-center text-white text-lg md:text-xl">
          BIOMONITOR III simplifies every stage of the ICM process offering:
          one-step injection, fully automatic Home Monitoring and clearer
          signals for easier rhythm classification.
        </div>
      </div>
      <div className="container mx-auto p-4 text-left p-4 md:flex">
        <div className="flex-1">
          <div className="text-center text-2xl font-semibold p-4">
            Why BIOMONITOR III?
          </div>
          <div className="md:flex items-center">
            <div className="w-32 md:w-16 mx-auto md:mx-0">
              <ImgBIO31125_W800 className="w-1" />
            </div>
            <div className="font-semibold text-lg mb-2 md:mb-0">
              One-Step Injection. Ideal for In-Office.
            </div>
          </div>
          <ul className="list-disc list-outside md:mb-6">
            <li className="ml-6">
              BIOMONITOR III comes ready to inject, pre-loaded into a single
              piece injection tool for one-step injection.
            </li>
            <li className="ml-6">
              Procedural simplicity makes it ideal for in-office settings.
            </li>
          </ul>
          <div className="md:flex items-center">
            <div className="w-32 md:w-16 mx-auto md:mx-0">
              <ImgBIO31090_W800 className="w-1" />
            </div>
            <div className="font-semibold text-lg mb-2 md:mb-0">
              Automatic Home Monitoring. No Setup Required.
            </div>
          </div>
          <ul className="list-disc list-outside md:mb-6">
            <li className="ml-6">
              BIOMONITOR III works with BIOTRONIK’s fully automatic Home
              Monitoring. Just plug it into a power outlet and it is up and
              running.
            </li>
            <li className="ml-6">
              Home Monitoring achieves industry leading transmission success
              rates, along with high levels of patient adherence and
              satisfaction.
            </li>
          </ul>

          <div className="md:flex items-center">
            <div className="w-32 md:w-16 mx-auto md:mx-0">
              <ImgBIO31124_W800 className="w-1" />
            </div>
            <div className="font-semibold text-lg mb-2 md:mb-0">
              High Quality Signal. Easy Rhythm Classification.
            </div>
          </div>
          <ul className="list-disc list-outside mb-6">
            <li className="ml-6">
              BIOMONITOR III combines our BIOvector design along with fractal
              coating on the sensing surfaces and a smart lossless compression
              algorithm deliver high amplitude signal quality.
            </li>
            <li className="ml-6">
              Intelligent Memory Management ensures that the most relevant
              clinical data is transmitted and stored across all of the five
              arrhythmia types.
            </li>
          </ul>
        </div>
        <div className="flex-1 self-center">
          <ImgBIO30037_W800 />
        </div>
      </div>
      <div className="bg-bioGrayBlue py-12">
        <div className="container mx-auto p-4 text-center text-white text-lg md:text-xl">
          <div>
            “BIOMONITOR ICMs have a proven track record for exceptional signal
            quality. BIOMONITOR III expands the existing diagnostic utility of
            these devices while simplifying the procedure. BIOMONITOR III is an
            exceedingly small device with market-leading four year longevity.
            When physicians are not burdened by poor signal quality, clinically
            irrelevant data, inaccurate, or worse, missed detections – more time
            can be devoted to ensuring a timely and accurate diagnosis.” <br />-
            Dr. Raul Weiss, Columbus, Ohio
          </div>
        </div>
      </div>
      <div className="container mx-auto w-full p-4">
        <div className="text-center my-8 text-xl md:text-3xl">
          Listen to Dr. Yunus on the future of ICM technologies
        </div>
        <Video url="https://biotronik.wistia.com/medias/u0oh7jv8vw" />
        <div className="text-center mt-8 text-xl md:text-3xl">What's next?</div>
        <div className="text-center mb-4 md:text-xl">
          Contact your local BIOTRONIK sales representative to learn more
        </div>
      </div>
    </Layout>
  );
}

export default BM3Physican;
